const hamburgerMenu = () => {
  let hamburger = document.querySelector('.hamburger--spring');
  let navContent = document.querySelector('.nav__content');
  let linksSp = document.querySelector('.nav__links-sp');

  if(hamburger != undefined) {
    hamburger.addEventListener('click', () => {
      if(hamburger.classList.contains('is-active')) {
        hamburger.classList.remove('is-active');
        navContent.classList.remove('nav-active');
        linksSp.classList.remove('show');
      }
      else {
        hamburger.classList.add('is-active');
        navContent.classList.add('nav-active');
        linksSp.classList.add('show');
      }
    })
  }
}

export default hamburgerMenu