/**
 * Modal
 */

const modal = () => {
  const myModal = document.querySelector('.js-modal');
  const closeButton = myModal.querySelector('.js-modal-close');

  const removeModal = () => {
    myModal.classList.remove('active');
    myModal.remove();
    document.documentElement.classList.remove('overflow');
    localStorage.setItem('hidden', 'true');
  }

  if (myModal && closeButton) {
    if (!localStorage.getItem('hidden')) {
      myModal.classList.add('active');
      document.documentElement.classList.add('overflow');
    }
    else {
      removeModal();
    }

    closeButton.addEventListener('click', (e) => {
      e.preventDefault();
      removeModal();
    });
  }
}
 
export default modal;
