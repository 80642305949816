const animationFunction = () => {
  let swipeRight = document.querySelectorAll('.swipe-right')

  for (let x = 0; x < swipeRight.length; x++) {
    if (swipeRight[x] != undefined) {
      if (swipeRight[x] != undefined) {
        let myElementHeight = swipeRight[x].offsetHeight;
        let myElementWidth = swipeRight[x].offsetWidth;
        let bounding = swipeRight[x].getBoundingClientRect();

        if (bounding.top >= -myElementHeight &&
          bounding.left >= -myElementWidth &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth) + myElementWidth &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + myElementHeight) {

            swipeRight[x].classList.add('animate-start');

        }
      }
    }
  }
}

export default animationFunction