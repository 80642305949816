import navScroll from './components/navScroll'
import hamburgerMenu from './components/hamburgerMenu';
import contactFunction from './components/contactFunctions';
import animationFunction from './components/animationFunctions';
import modal from './components/modal';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    hamburgerMenu();
    contactFunction();
    animationFunction();
    modal();

    window.onscroll = () => {
      navScroll();
      animationFunction();
    }
  },
  false
)
