/**
 * change navigation background when scrolling
 */

const navScroll = () => {
  let navContent = document.querySelector('.nav__content');

  if(navContent != undefined) {
    if(window.scrollY >= (window.innerHeight / 4)) {
      navContent.classList.add('scroll');
    }
    else {
      navContent.classList.remove('scroll');
    }
  }
}

export default navScroll
